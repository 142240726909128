'use client';

import { Carousel } from '@c/index';
import HomeSection from './HomeSection';
import { getOnSale } from '@util/firestore/products';
import MXProductCard from '@c/cards/MXProductCard';
import { useQuery } from '@tanstack/react-query';

export default function OnSaleProducts() {
  const { data: saleProducts } = useQuery({
    queryKey: ['saleProducts'],
    queryFn: () => getOnSale(),
  });

  if (!saleProducts?.results.length) return null;

  return (
    <HomeSection
      title="On Sale"
      className={`mx-auto`}
      linkText="See All"
      href="/search/on-sale"
    >
      <Carousel
        items={saleProducts?.results.map((product) => (
          <MXProductCard
            product={product}
            attribution={{
              data_source: 'typesense',
              page: 'home',
              section: 'on-sale',
            }}
            key={product.id}
          />
        ))}
      />
    </HomeSection>
  );
}
