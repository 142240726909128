'use client';

import CarouselComponent from '@ui/Carousel';
import { isMobile } from '@util/index';
import { BlogDocument } from '@util/types/firestore/blog';
import { useRef } from 'react';
import PaddockPostCard from './components/PaddockPost';

export default function ThePaddock({ posts }: { posts: BlogDocument[] }) {
  const carouselfRef = useRef<any>(null);

  return (
    <div className="mt-4 flex">
      <div className="relative w-full">
        {isMobile() ? (
          <div
            className="no-scrollbar flex w-screen gap-x-8 overflow-x-scroll scroll-smooth px-4 pb-[1rem] pl-8 sm:px-0 lg:overflow-x-hidden"
            ref={carouselfRef}
          >
            {posts.map((post) => (
              <div className="flex-shrink-0" key={post.id}>
                <PaddockPostCard post={post} />
              </div>
            ))}
          </div>
        ) : (
          <CarouselComponent
            items={posts.map((post) => (
              <PaddockPostCard key={post.id} post={post} />
            ))}
            incrementBy={1}
            basis="basis-1/3.5"
          />
        )}
      </div>
    </div>
  );
}
