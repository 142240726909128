import SafeImage from '@ui/SafeImage';
import { prefix } from '@util/imagePrefix';
import { isMobile } from '@util/index';
import UpgradeNowTrigger from 'app/boot-trade-in-program/_components/UpgradeNowTrigger';

type BootTradeInSectionProps = {
  bg?: 'red' | 'white';
};

export const BootTradeInSection = ({ bg = 'red' }: BootTradeInSectionProps) => {
  const title = isMobile()
    ? 'Upgrade your boots today!'
    : 'Trade in Your Boots & Upgrade for Less!';

  return (
    <div
      className={`mb-[5rem] mt-[8rem] flex w-full items-center justify-center px-8 sm:mx-auto sm:mb-0 sm:mt-[10rem] sm:max-w-[150rem] sm:px-0`}
    >
      <div
        className={`relative flex h-[58rem] w-full flex-col rounded-[3rem] ${
          bg === 'red' ? 'bg-[#F9E8E8]' : 'bg-white'
        } sm:h-[50rem] sm:flex-row`}
      >
        <SafeImage
          src={`${prefix}/assets/home-trade-in-floating-boots_1920x2560.png`}
          alt="Boot Trade-In Program"
          className="absolute -top-[8rem] h-[38rem] w-full object-cover sm:-left-32 sm:-top-[15rem] sm:h-[70rem] sm:w-auto"
          height={3840}
          width={7200}
        />

        <div className="absolute top-[13rem] flex h-full w-full items-center justify-center sm:left-[35rem] sm:top-0">
          <div className="flex flex-col gap-y-2">
            <span className="hidden text-[1.8rem] font-semibold text-[#444444] sm:block">
              Boot Trade-In Program
            </span>

            <h3 className="w-full text-center text-[2.5rem] font-bold text-black sm:w-[43rem] sm:text-left sm:text-[3.7rem]">
              {title}
            </h3>

            <div className="flex flex-col gap-y-6">
              <span className="w-full text-center text-[1.8rem] font-medium text-[#444444] sm:w-[55rem] sm:text-left">
                Get credit towards your next pair of boots by trading in your
                old ones. It&apos;s easy, fast, and environmentally friendly!
              </span>

              <div className="flex w-full justify-center sm:justify-start">
                <UpgradeNowTrigger title="Start Your Trade-In Now!" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
