import { HeartIcon } from '@c/icons';
import SafeImage from '@ui/SafeImage';
import { formatCurrency, getCDNUrl } from '@util/index';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useAuth } from 'context/AuthContext';
import {
  addProductToFavorites,
  removeProductFromFavorites,
} from '@util/firestore/products';
import { useQueryClient } from '@tanstack/react-query';
import { MotionButton, MotionDiv } from 'motion';
import { ProductDocument } from 'models/product';
import { getProductSlug } from '@util/urlHelpers';
import Link from 'next/link';

dayjs.extend(duration);

type FlashCardProps = {
  product: ProductDocument;
};

export default function FlashCard({ product }: FlashCardProps) {
  const { userDoc } = useAuth();
  const [isLiked, setLiked] = useState(
    userDoc?.favorites?.includes(product.id) ?? false
  );

  const [endsIn, setEndsIn] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00',
  });
  const [isAnimating, setIsAnimating] = useState(false);

  const queryClient = useQueryClient();

  const handleLikeClick = async () => {
    if (!userDoc) return;
    const isFavorite = userDoc?.favorites.includes(product.id);
    if (isFavorite) {
      await removeProductFromFavorites(userDoc.uid, product.id);
    } else {
      await addProductToFavorites(userDoc.uid, product.id);
    }

    setLiked(!isLiked);

    if (!isLiked && !isAnimating) {
      setIsAnimating(true);
    }

    queryClient.invalidateQueries({
      queryKey: ['authUser'],
    });
  };

  useEffect(() => {
    const nextDayDate = dayjs(product.end_time);
    const updateCountdown = () => {
      const now = dayjs();
      const diff = nextDayDate.diff(now);
      const duration = dayjs.duration(diff);

      const hours = String(duration.hours()).padStart(2, '0');
      const minutes = String(duration.minutes()).padStart(2, '0');
      const seconds = String(duration.seconds()).padStart(2, '0');

      setEndsIn({ hours, minutes, seconds });
    };

    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [product.end_time]);

  const percentOff = Math.round(
    ((product.og_price - product.price) / product.og_price) * 100
  );
  return (
    <Link
      href={getProductSlug(product)}
      className="relative flex h-[40rem] w-[22rem] flex-col justify-between rounded-[1.5rem] sm:h-[50rem] sm:w-[28.5rem]"
    >
      <div className="relative w-full rounded-[1.5rem] bg-brand-primary-lighter">
        <div className="absolute left-0 right-0 top-0 flex justify-end rounded-[1.5rem] bg-gradient-to-b from-black/30 to-transparent p-4">
          <div className="hidden h-[3.5rem] w-[3.5rem] cursor-pointer items-center justify-center rounded-full bg-white p-2 sm:flex">
            <MotionButton
              onClick={handleLikeClick}
              animate={isAnimating ? { scale: [1, 1.2, 1] } : {}}
              transition={{ duration: 0.3 }}
              onAnimationComplete={() => setIsAnimating(false)}
              className="flex h-full w-full items-center justify-center"
            >
              {isLiked ? (
                <HeartIcon pathProps={{ fill: '#FF4242', stroke: 'none' }} />
              ) : (
                <HeartIcon />
              )}
            </MotionButton>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 right-0 flex justify-start bg-gradient-to-t from-black/30 to-transparent p-4">
          <span className="rounded-[1rem] bg-brand-secondary px-4 py-2 text-[2rem] font-semibold text-white sm:text-[1.6rem]">
            {percentOff}% Off
          </span>
        </div>

        <SafeImage
          alt={product.title}
          src={getCDNUrl(product.thumbnail)}
          classes="h-[20rem] sm:h-[30rem] object-cover aspect-square w-full rounded-t-[1.5rem] object-cover"
        />
      </div>

      <div className="flex h-[20rem] w-full flex-col justify-between rounded-b-[1.5rem] bg-[#333333] p-4">
        <h3 className="line-clamp-2 w-full text-center text-[1.8rem] font-semibold text-white sm:text-[1.7rem]">
          {product?.title}
        </h3>

        <div className="mt-4 space-y-4">
          <div className="flex w-full items-center gap-x-4 rounded-[1.5rem] bg-brand-white/10 p-4 px-8 text-[1.7rem]">
            <h3 className="font-semibold text-brand-white">PRICE</h3>
            <h3 className="font-semibold text-[#D1FFF5]">
              {formatCurrency(product.price)}
            </h3>
          </div>

          <div className="flex w-full items-center justify-center gap-x-4 p-4 px-8 text-center text-[1.8rem] sm:justify-start sm:text-[1.7rem]">
            <h3 className="font-semibold text-brand-white">ENDS</h3>
            <h3 className="font-semibold text-[#EF7A71]">
              {`${Math.abs(Number(endsIn.hours))
                .toString()
                .padStart(2, '0')}:${Math.abs(Number(endsIn.minutes))
                .toString()
                .padStart(2, '0')}:${Math.abs(Number(endsIn.seconds))
                .toString()
                .padStart(2, '0')}`}
            </h3>
          </div>
        </div>
      </div>
    </Link>
  );
}
