import Carousel from '@ui/Carousel';
import SafeImage from '@ui/SafeImage';
import { prefix } from '@util/imagePrefix';
import Link from 'next/link';

const popCats = [
  {
    name: 'Boots',
    slug: 'boots',
    href: '/shop/riding-gear/dirt-bike-and-motocross-boots',
  },
  {
    name: 'Helmets',
    slug: 'helmets',
    href: '/shop/riding-gear/dirt-bike-and-motocross-helmets',
  },
  {
    name: 'Protective',
    slug: 'protective',
    href: '/shop/riding-gear/dirt-bike-and-motocross-protective',
  },
  {
    name: 'Exhaust',
    slug: 'exhaust',
    href: '/shop/dirt-bike-parts/exhaust',
  },
  {
    name: 'Suspension',
    slug: 'suspension',
    href: '/shop/dirt-bike-parts/suspension',
  },
  {
    name: 'Engine',
    slug: 'engine',
    href: '/shop/dirt-bike-parts/engine',
  },
  {
    name: 'Bars & Controls',
    slug: 'bars-controls',
    href: '/shop/dirt-bike-parts/bars-controls',
  },
  {
    name: 'Body Parts & Accessories',
    slug: 'body-parts-accessories',
    href: '/shop/dirt-bike-parts/body-parts-accessories',
  },
];

export const PopularCategories = () => {
  return (
    <div className="flex w-full flex-col items-start gap-y-12 px-8 sm:mx-auto sm:max-w-[150rem] sm:px-0">
      <div className="flex w-full justify-start">
        <h2 className={'text-[2.4rem] font-semibold text-black'}>
          Popular Categories
        </h2>
      </div>

      <div className="relative w-full">
        <Carousel
          items={popCats.map((cat) => (
            <CategoryCard key={cat.slug} {...cat} />
          ))}
          basis="basis-1/1.5 sm:basis-1/4"
        />
      </div>
    </div>
  );
};

type CategoryCardProps = {
  name: string;
  slug: string;
  href: string;
};

const CategoryCard = ({ name, href, slug }: CategoryCardProps) => {
  const isBodyParts = name === 'Body Parts & Accessories';
  const imageUrl = isBodyParts
    ? `${prefix}/assets/popular-categories/popular-categories-body-parts_1920x2560.png`
    : `${prefix}/assets/popular-categories/popular-categories-${slug}_1920x2560.png`;

  return (
    <Link
      href={href}
      className="group mb-32 flex h-[25rem] w-[25rem] items-center justify-center rounded-[2rem] bg-[#F1F1F1] p-6 transition-colors duration-300 hover:bg-[#E5E5E5] sm:h-[36rem] sm:w-[36rem]"
    >
      <div className="flex flex-col items-center gap-y-4">
        <div className="overflow-hidden rounded-[2rem]">
          <SafeImage
            src={imageUrl}
            alt={slug}
            className={`h-[15rem] w-[15rem] object-cover transition-transform duration-300 group-hover:scale-110 sm:h-[25rem] sm:w-[25rem] ${
              isBodyParts ? 'pt-20' : ''
            }`}
          />
        </div>

        <span className="w-[20rem] text-center text-[2.5rem] font-bold">
          {name}
        </span>
      </div>
    </Link>
  );
};
