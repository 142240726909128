import Button from '@ui/Button';
import Carousel from '@ui/Carousel';
import { useAuth } from 'context/AuthContext';
import useMyFeed from '../hooks/useMyFeed';
import { renderProductCards } from '../utils';
import HomeSection from './Home/components/HomeSection';

export const GearInYourSize = () => {
  const { data: myFeed, isLoading } = useMyFeed();

  if (!myFeed?.gear.length) return null;

  return (
    <HomeSection title="Gear In Your Size">
      <Carousel
        loading={isLoading}
        items={renderProductCards(
          myFeed?.gear
            .sort((a, b) => (Math.random() - 0.5 > 0 ? 1 : -1))
            .slice(0, 5),
          {
            list_name: 'gear_for_you',
            list_id: 'gear_for_you',
          },
          {
            data_source: 'typesense',
            page: 'homepage',
            section: 'gear_for_you',
          }
        )}
      />
    </HomeSection>
  );
};

export const PartsForYourBikes = () => {
  const { data: myFeed, isLoading } = useMyFeed();

  if (!myFeed?.parts.length) return null;

  return (
    <>
      {myFeed.parts.map(({ bike, products }) => {
        if (!bike) return null;
        if (!products.length) return null;

        return (
          <HomeSection
            key={JSON.stringify(bike)}
            title={`Parts for your ${bike.year} ${bike.make} ${bike.model}`}
          >
            <Carousel
              loading={isLoading}
              items={renderProductCards(
                products,
                {
                  list_name: 'parts_for_you',
                  list_id: 'parts_for_you',
                },
                {
                  data_source: 'typesense',
                  page: 'homepage',
                  section: 'parts_for_you',
                }
              )}
            />
          </HomeSection>
        );
      })}
    </>
  );
};

const MyFeed = () => {
  const { userDoc } = useAuth();

  if (!userDoc) {
    return (
      <div className="flex h-[30rem] w-full items-center justify-center">
        <div className="flex flex-col items-center">
          <h1 className="text-[2rem] font-semibold">
            You&apos;re not signed in
          </h1>
          <p className="text-[1.6rem]">
            Please sign in to view gear in your size
          </p>

          <Button
            text="Sign In"
            href="/login"
            className="mt-4"
            type="secondary"
            radius={'xl'}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-y-8">
      <GearInYourSize />
      <PartsForYourBikes />
    </div>
  );
};

export default MyFeed;
