import { useState } from 'react';
import Hammer from 'react-hammerjs';
import SafeImage from '@ui/SafeImage';

import shopping from './assets/shopping.svg';
import team from './assets/team.svg';
import dollarHand from './assets/dollar-hand.svg';
import shieldStar from './assets/shield-star.svg';
import { isMobile } from '@util/index';

type MxReason = {
  title: string;
  text: string;
  image: string | JSX.Element;
};

const ReasonBlock = ({ title, text, image }: MxReason) => {
  return (
    <div
      className={`${
        isMobile() && 'mb-8'
      } flex h-fit flex-col items-center justify-center gap-y-4 text-center sm:items-start sm:text-left lg:h-32 lg:flex-col lg:gap-y-8`}
    >
      <div className="h-[9rem] w-[9rem] rounded-full bg-white p-4 lg:h-32 lg:w-32 lg:p-2">
        <SafeImage src={image} alt={title} className="h-full w-full" />
      </div>

      <div className="mt-4 flex flex-col items-center gap-y-8 sm:items-start lg:ml-0 lg:mt-0">
        <h2 className="text-3xl font-semibold">{title}</h2>

        <p className="mt-4 text-[1.6rem] font-light text-zinc-700 lg:mt-0 lg:text-2xl">
          {text}
        </p>
      </div>
    </div>
  );
};

const reasons: MxReason[] = [
  {
    title: 'Shop new and used.',
    text: 'Discover a diverse selection of both new and used items, perfect for any budget and style.',
    image: shopping,
  },
  {
    title: 'A community built for you.',
    text: 'Build relationships with buyers and sellers who share the same passions.',
    image: team,
  },
  {
    title: 'Turn your items into cash.',
    text: 'Have items you no longer need? List your items in just a few steps, and start earning.',
    image: dollarHand,
  },
  {
    title: 'Buyer and seller protection.',
    text: "Enjoy peace of mind with every transaction. We've got you covered every step of the way.",
    image: shieldStar,
  },
];

export default function WhyMX() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < reasons.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : reasons.length - 1
    );
  };

  const handleSwipe = (direction: number) => {
    if (direction === 4) {
      handlePrev();
    } else if (direction === 2) {
      handleNext();
    }
  };

  return (
    <div className="-mt-7 w-full bg-[#F1F1F1] py-32 lg:py-72">
      <div className="mx-auto max-w-[144rem]">
        <div className="flex w-full justify-center">
          <h2 className="text-5xl font-semibold">Why MX Locker?</h2>
        </div>

        <div className="relative flex justify-center px-16 py-32 lg:px-default-padding-x">
          <div className="block lg:hidden">
            {/* @ts-ignore */}
            <Hammer onSwipe={(e) => handleSwipe(e.direction)}>
              <div className="flex max-w-full justify-center overflow-hidden">
                <div
                  className="flex transition-transform duration-500"
                  style={{
                    transform: `translateX(-${activeIndex * 100}%)`,
                    width: `${reasons.length * 100}%`,
                  }}
                >
                  {reasons.map((reason, index) => (
                    <div
                      key={index}
                      className="flex w-full flex-shrink-0 justify-center"
                    >
                      <ReasonBlock
                        title={reason.title}
                        text={reason.text}
                        image={reason.image}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Hammer>

            <button
              aria-hidden
              tabIndex={-1}
              onClick={handlePrev}
              aria-label="previous"
              className="absolute left-4 top-1/2 hidden -translate-y-1/2 transform rounded-full bg-gray-800 p-2 text-white hover:bg-gray-600 lg:block"
            >
              &larr;
            </button>
            <button
              aria-hidden
              tabIndex={-1}
              aria-label="next"
              onClick={handleNext}
              className="absolute right-4 top-1/2 hidden -translate-y-1/2 transform rounded-full bg-gray-800 p-2 text-white hover:bg-gray-600 lg:block"
            >
              &rarr;
            </button>

            <div className="mt-4 flex justify-center gap-2">
              {reasons.map((_, index) => (
                <button
                  aria-label="slide"
                  aria-hidden
                  tabIndex={-1}
                  key={index}
                  className={`${
                    activeIndex === index ? 'bg-zinc-700' : 'bg-gray-300'
                  } h-4 w-12 rounded-full transition-colors hover:bg-gray-500`}
                  onClick={() => setActiveIndex(index)}
                />
              ))}
            </div>
          </div>

          <div className="hidden sm:mt-24 sm:grid sm:grid-cols-4 sm:items-start sm:gap-x-12 lg:items-stretch lg:gap-y-8">
            {reasons.map((reason, index) => (
              <div
                key={index}
                className="flex w-full flex-shrink-0 justify-center sm:flex-none lg:flex-col lg:justify-between"
              >
                <ReasonBlock
                  title={reason.title}
                  text={reason.text}
                  image={reason.image}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
