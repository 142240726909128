import { Road2RecoveryIcon } from '@c/icons';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import { prefix } from '@util/imagePrefix';

export default function R2RHomepageGraphic() {
  return (
    <>
      <div className="mb-[3rem] flex w-full bg-[#E9F7F0] px-[2.5rem] py-[3rem] sm:hidden">
        <div className="flex flex-col gap-y-8">
          <div className="flex flex-col gap-y-2">
            <h1 className="text-[2.1rem] font-bold text-[#2BAE67]">
              Chance to Win The Ultimate Ride!
            </h1>

            <p className="text-[1.8rem] font-medium text-[#444444]">
              Join our ultimate giveaway to win 2025 Triumph TF 250-X, 2025 KTM
              350SX-F and more!
            </p>
          </div>

          <Button
            text="Get your entry today!"
            className="w-[25rem] !text-[1.8rem] !font-bold"
            href={`/2025-r2r-ultimate-supercross-giveaway`}
          />
        </div>
      </div>
      <div className="mb-8 hidden flex-col items-center sm:flex">
        <div className="px-[17.5rem] pb-[3rem]">
          <div className="absolute flex h-[40rem] w-[150rem] items-center justify-between px-[10rem]">
            <SafeImage
              src={`${prefix}/assets/r2r-giveaway-hero_1920x2560.png`}
              alt="2025 R2R Ultimate Supercross Giveaway"
              className="h-[22.5rem] w-auto"
              height={1000}
              width={1000}
            />

            <div className="flex flex-col gap-y-8">
              <div className="flex flex-col gap-y-2">
                <h1 className="text-[3rem] font-bold text-white">
                  Chance to Win The Ultimate Ride!
                </h1>

                <p className="w-[45rem] text-[2.1rem] text-white">
                  Join our ultimate giveaway to win 2025 Triumph TF 250-X, 2025
                  KTM 350SX-F and more!
                </p>
              </div>

              <Button
                text="Get your entry today!"
                className="w-[25rem] bg-white !text-[1.8rem] !font-bold !text-black"
                href={`/2025-r2r-ultimate-supercross-giveaway`}
              />
            </div>
          </div>

          <SafeImage
            src={`${prefix}/assets/r2r-giveaway-bg_1920x2560.png`}
            alt="background"
            className="h-[40rem] w-[150rem] rounded-[3rem] object-cover"
            width={2560}
            height={2560}
            priority
            loading="eager"
          />
        </div>

        <div className="flex w-full justify-center">
          <div className="flex items-center gap-x-2">
            <Road2RecoveryIcon className="h-[5rem] w-[5rem]" variant="black" />

            <p className="text-[1.8rem] font-medium text-black">
              By entering, you&apos;re not just paying to win—you&apos;re giving
              back to the motocross community.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
