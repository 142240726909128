import { staticCategories } from '@c/navigation/utils/categories';
import Link from 'next/link';

export default function HomepageCats() {
  return (
    <div className="my-8 flex w-full items-center gap-x-6 pl-8 sm:hidden">
      <div className="scrollbar-hide scroll-0 flex gap-x-6 overflow-auto scrollbar-none">
        {staticCategories.map((category, index) => (
          <CategoryBadge
            key={index}
            category={{
              name: category.label,
              url: category.href,
            }}
          />
        ))}
      </div>
    </div>
  );
}

function CategoryBadge({
  category,
}: {
  category: { name: string; url: string };
}) {
  return (
    <Link
      href={category.url}
      className="flex w-[13rem] min-w-[13rem] max-w-[13rem] items-center justify-center rounded-full border border-zinc-300 bg-brand-off-white px-6 py-4 text-center text-[1.6rem]"
    >
      {category.name}
    </Link>
  );
}
