'use client';

import ProductCardSkeleton from '@c/skeletons/ProductCardSkeleton';
import { ProductDocument } from 'models/product';
import Link from 'next/link';
import CarouselComponent from '@ui/Carousel';
import FlashCard from './components/FlashCard';

type FlashDealsProps = {
  title: string;
  isFetching: boolean;
  allProducts: ProductDocument[];
};

export default function FlashDeals({
  title,
  isFetching,
  allProducts,
}: FlashDealsProps) {
  return (
    <div className="flex w-full flex-col bg-black px-4 py-16 lg:p-16">
      <div className="w-full sm:mx-auto sm:max-w-[150rem]">
        <div className="flex w-full items-center justify-between gap-x-8 sm:max-w-content">
          <h4 className="pl-8 text-[3rem] font-semibold text-white lg:pl-0">
            {title ?? 'Flash Deals ⚡️'}
          </h4>

          <Link
            href="/shop/curated/flash-deals"
            className="text-[1.8rem] text-white"
          >
            See All
          </Link>
        </div>

        <div className="mt-8 sm:max-w-content">
          <div className="relative w-full">
            {allProducts && allProducts.length > 0 ? (
              <>
                <CarouselComponent
                  items={allProducts.map((product) => (
                    <FlashCard product={product} key={product.id} />
                  ))}
                />
              </>
            ) : isFetching ? (
              <div className="flex gap-[1.6rem]">
                <ProductCardSkeleton key="skeleton-1" />
                <ProductCardSkeleton key="skeleton-2" />
                <ProductCardSkeleton key="skeleton-3" />
                <ProductCardSkeleton key="skeleton-4" />
                <ProductCardSkeleton key="skeleton-5" />
              </div>
            ) : (
              <div className="flex gap-[1.6rem]">
                <h3>No flash deals found</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
