const trendingSearches = {
  terms: [
    {
      img: 'https://cdn.mxlocker.com/hXwGrEjFGdV3tH9DX9D6s5ghEak1/products/YidCIWFIRt35SpSeitY1/1720476573504053997_1920x2560',
      index: 0,
      label: 'Tech 10',
    },
    {
      img: 'https://cdn.mxlocker.com/Upv1KiSnmVdgjtZLxny7WabvRPH3/products/n3tAcAu0gM9LRFtVqHwK/image1693251179423.webp',
      index: 1,
      label: 'Exhaust',
    },
    {
      img: 'https://cdn.mxlocker.com/hQNAkpyEgsTsejVrj7YOxx5GJmp2/products/v6UpSAvnTx1MuFXwFJKD/image_0_1730510975881_1920x2560',
      label: 'A-Kit',
      index: 2,
    },
    {
      index: 6,
      img: 'https://cdn.mxlocker.com/kR7enR2SgIc5fYiCC9ZjFI0MMHO2/products/5J9sknOlPEGH86QYE5PA/image1721894351986_1920x2560',
      label: 'Bell Moto 10',
    },
    {
      img: 'https://cdn.mxlocker.com/uEO8j6sO1XQv6s9Aj4jM/products/hzPugWR6QjL8Sm95VpTU/image267060788018_0_1731351449898_1920x2560',
      index: 4,
      label: 'Wheels',
    },
    {
      index: 5,
      label: 'Triple Clamps',
      img: 'https://cdn.mxlocker.com/OF9zeVTHn9VdTzDv20nap23kkiG3/products/Ws6TM3QY8Fsg7pyNYtcw/1725400100340084123_1920x2560',
    },
    {
      label: 'Fox Instinct',
      img: 'https://cdn.mxlocker.com/74BziBpuFhQhW0Lx5QKWdh8w0jn1/products/sTyZiJirnfWhFS1ru3x1/image1721940759041_1920x2560',
      index: 3,
    },
    {
      index: 7,
      label: 'Vortex ECU',
      img: 'https://cdn.mxlocker.com/qlcEanuNfBZOpI7CmwgLN0QKPlo1/products/26ZD8rnMwNYl40hqtgLM/image_0_1729608741516_1920x2560',
    },
    {
      label: 'Complete Engine',
      index: 8,
      img: 'https://cdn.mxlocker.com/uEO8j6sO1XQv6s9Aj4jM/products/BlwA09TugtZShFsBVlzo/image267060788030_0_1731351467888_1920x2560',
    },
    {
      img: 'https://cdn.mxlocker.com/BKYGuvXld0WPz1K8BRzspooEunD2/products/y589IcqRls3GvPQ2NxaH/image_0_1728752817688_1920x2560',
      index: 9,
      label: '100% Armega',
    },
    {
      label: 'Aektiv',
      img: 'https://cdn.mxlocker.com/FXK4OyXFHofIStk1I2YvG1TiPGm1/products/V9eyM0W3A0qkQBzYcCHA/image8282809729195_0_1732129058020_1920x2560',
      index: 10,
    },
    {
      label: 'Suspension',
      index: 11,
      img: 'https://cdn.mxlocker.com/sK0niGLiqQYR5UsrX4wTSzw7sSv2/products/Dhw734YkAreILFafmcgX/image1691949297753.webp',
    },
  ],
};

import Link from 'next/link';
import HomeSection from './HomeSection';
import SafeImage from '@ui/SafeImage';

export const TrendingSearches = () => {
  const halfLength = Math.ceil(trendingSearches.terms.length / 2);
  const firstRow = trendingSearches.terms.slice(0, halfLength);
  const secondRow = trendingSearches.terms.slice(halfLength);

  return (
    <HomeSection title="Trending Searches" className='!-mt-16 sm:!-mt-32'>
      <div className="max-w-[100%] overflow-x-scroll scrollbar-none">
        <div className="flex flex-col gap-[1rem] px-[1.6rem] py-4">
          <div className="flex gap-[1rem]">
            {firstRow.map((search) => (
              <SearchPill
                key={search.label}
                name={search.label}
                src={search.img}
                classes="shrink-0"
              />
            ))}
          </div>
          <div className="flex gap-[1rem]">
            {secondRow.map((search) => (
              <SearchPill
                key={search.label}
                name={search.label}
                src={search.img}
                classes="shrink-0"
              />
            ))}
          </div>
        </div>
      </div>
    </HomeSection>
  );
};

const SearchPill = ({
  src,
  name,
  classes,
}: {
  name: string;
  src: string;
  classes: string;
}) => {
  return (
    <Link
      scroll={false}
      href={`/search?keyword=${encodeURI(name.toLowerCase())}`}
      className={`group flex h-[5.5rem] w-fit items-center gap-8 overflow-clip rounded-[1.5rem] bg-[#F1F1F1] pr-4 font-semibold sm:h-[7rem] sm:max-w-[36rem] ${classes} relative cursor-pointer transition-colors duration-300 hover:bg-[#E5E5E5] hover:text-black`}
    >
      <div className="relative aspect-square w-[5.5rem] overflow-hidden sm:w-[9rem]">
        <SafeImage
          src={src}
          alt={name + ' image'}
          id="mobile"
          fill
          classes="object-cover transition-transform duration-300 group-hover:scale-110"
        />
      </div>

      <span className="pr-2 text-[1.8rem] transition-transform sm:text-[2.1rem]">
        {name}
      </span>
    </Link>
  );
};
